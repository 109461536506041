import React from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Container from '../../../Container';
import Gallery from '../Gallery';
import Button from '../../../Button';
import profiles from '../../../../../content/the_band/profiles.json';
import { markdownParser } from '../../../../utils';
import s from './Default.scss';
import intro from '../../../../../content/the_band/intro.json';
import whatWeAre from '../../../../../content/the_band/whatWeAre.json';
import whatWeAreNot from '../../../../../content/the_band/whatWeAreNot.json';
import vision from '../../../../../content/the_band/vision.json';
import audition from '../../../../../content/the_band/audition.json';
import Layout from '../../../layout';

const introContent = intro.content.find((data) => data.display);
const whatWeAreContent = whatWeAre.content.find((data) => data.display);
const whatWeAreNotContent = whatWeAreNot.content.find((data) => data.display);
const visionContent = vision.content.find((data) => data.display);
const auditionContent = audition.content.find((data) => data.display);

const Default = ({ location }) => (
  <Layout location={location}>
    <div className="TheBand">
      <div className="banner" />
      <section className="bgSky top">
        <Container className="container-modefied">
          <div className="content">
            <h1 className="title">
              <b>{introContent?.title}</b>
              {introContent?.subtitle}
            </h1>
            <div className="intro">
              <div className="paragraph">
                {markdownParser(introContent?.body)}
              </div>
            </div>
          </div>

          <div className="hero">
            <img
              src="/decorations/rockband-background.svg"
              alt=""
              className="illustration-background"
            />
            <img
              src="/decorations/rockBand.svg"
              alt=""
              className="illustration"
            />
          </div>
        </Container>
        <hr className="bubblesBorder" />
      </section>
      <section className="weAre bgPrimaryDark">
        <Container justifySpaceBetween>
          <div className="col">
            <h2 className="title">
              {whatWeAreContent?.title} <b>{whatWeAreContent?.titleBold}</b>
            </h2>
            <div className="paragraph">
              {markdownParser(whatWeAreContent?.body)}
            </div>
          </div>
          <div className="col">
            <h2 className="title">
              {whatWeAreNotContent?.title}{' '}
              <b>{whatWeAreNotContent?.titleBold}</b>
            </h2>
            <div className="paragraph">
              {markdownParser(whatWeAreNotContent?.body)}
            </div>
          </div>
        </Container>
      </section>

      <section id="meet-the-band" className="bgAccentLighter top">
        <Container flexDirection="flexColumn">
          <div>
            <h2 className="title profilesTitle">
              <div className="bold">{profiles?.title}</div>
              <div className="thin">{profiles?.subtitel}</div>
            </h2>
          </div>
          <Gallery images={profiles.profiles} />
        </Container>
      </section>

      <section className="">
        <Container className="vision">
          <div className="illustration">
            <img
              className="clouds"
              src="/decorations/clouds.svg"
              alt="clouds"
            />
            <img
              className="humanFigure"
              src="/decorations/figure1.svg"
              alt="illustration"
            />
          </div>
          <div className="content__vision">
            <h2 className="title">
              <b>{visionContent?.title}</b>
              <br />
              {visionContent?.subtitel}
            </h2>
            <div className="paragraph">
              {markdownParser(visionContent?.body)}
            </div>
            <Link to={visionContent?.buttonLink}>
              <Button
                design="simple"
                uppercase
                text={visionContent?.buttonText}
              />
            </Link>
          </div>
        </Container>
      </section>

      <section className="bgPrimaryLightest">
        <hr className="dashBorder" />
        <Container>
          <div className="content">
            <h2 className="title auditionTitle">
              <b>{auditionContent?.title}</b> {auditionContent?.titleThin}
            </h2>
            <div className="paragraph">
              {markdownParser(auditionContent?.body)}
            </div>
            <a href={auditionContent?.buttonLink1}>
              <Button
                design="main"
                uppercase
                text={auditionContent?.buttonText1}
              />
            </a>
          </div>
          <div className="illustration">
            <img src="/decorations/drumsPrimaryLight.svg" alt="" />
          </div>
        </Container>
      </section>
    </div>
  </Layout>
);
Default.propTypes = {
  location: PropTypes.shape().isRequired,
};
export default withStyles(s)(Default);
