import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import Profile from '../../components/modules/TheBand/Profile';
import Timeline from '../../components/modules/TheBand/Timeline';
import Default from '../../components/modules/TheBand/Default';

const TheBand = () => (
  <Router basepath={withPrefix('/the-band')}>
    <Default path="/" />
    <Profile path=":id" />
    <Timeline path="timeline" />
  </Router>
);

export default TheBand;
