import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import s from './ContactDetails.scss';

const ContactDetails = ({ content }) => (
  <ul className="contact__details">
    {content?.email && (
      <li>
        <p className="title">Email</p>
        <a href={`mailto: ${content?.email}`}>{content?.email}</a>
      </li>
    )}
    {content?.phone && (
      <li>
        <p className="title">Phone</p>
        <p>{content?.phone}</p>
      </li>
    )}
    {content?.socially && content?.socially.length > 0 && (
      <li>
        <p className="title">Socially</p>
        <div className="box__social">
          {content?.socially
            && content?.socially.map((item, index) => (
              <a key={`${index + Math.random()}${item?.icon}`} href={item?.url}>
                <i className={`socialIcons fab fa-${item?.icon}`} />
              </a>
            ))}
        </div>
      </li>
    )}
  </ul>
);

ContactDetails.propTypes = {
  content: PropTypes.shape().isRequired,
};

export default withStyles(s)(ContactDetails);
