import React, { useEffect, useState } from 'react';
import { withStyles, LinearProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { profiles } from '../../../../../content/the_band/profiles.json';
import { markdownParser } from '../../../../utils';
import Container from '../../../Container';
import s from './Profile.scss';
import Layout from '../../../layout';
import ContactDetails from './components/ContactDetails';

const isInViewport = (elem) => {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0
    && distance.bottom - 200
      <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

const StyledProgressBar = ({ color = '#3f51', level = '0', fill = false }) => {
  const useStyles = makeStyles({
    root: {
      width: '60%',
      borderRadius: 50,
      height: 29,
      backgroundColor: '#F0E1D5',
    },
    barColorPrimary: {
      borderRadius: 50,
      backgroundColor: color,
    },
    bar1Determinate: {
      transition: 'transform 1s linear;',
    },
  });

  const classes = useStyles();

  return (
    <LinearProgress
      classes={{
        root: classes.root,
        barColorPrimary: classes.barColorPrimary,
        bar1Determinate: classes.bar1Determinate,
      }}
      variant="determinate"
      value={fill ? Number(level * 10) : 0}
    />
  );
};

StyledProgressBar.defaultProps = {
  color: '#3f51',
  level: '0',
  fill: false,
};

StyledProgressBar.propTypes = {
  color: PropTypes.string,
  level: PropTypes.string,
  fill: PropTypes.bool,
};

const Profile = ({ id = null, location }) => {
  const [fill, setFill] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  let profile;
  for (let x = 0; x < profiles.length; x += 1) {
    if (profiles[x].slug === id) {
      profile = { content: profiles[x], index: x };
    }
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 900;

  useEffect(() => {
    const findMe = document.querySelector('#profileSkills');

    if (findMe) {
      if (isInViewport(findMe)) {
        setFill(true);
      }

      window.addEventListener(
        'scroll',
        () => {
          if (isInViewport(findMe)) {
            setFill(true);
          }
        },
        false,
      );
    }
  });

  if (!profile) {
    window.location = '/404';
    return null;
  }

  const { content = {}, content: { skills = [] } = {} } = profile || {};

  const nextProfile = `/the-band/${
    profiles[profile.index + 1]
      ? profiles[profile.index + 1]?.slug
      : profiles[0]?.slug
  }`;
  const prevProfile = `/the-band/${
    profiles[profile.index - 1]
      ? profiles[profile.index - 1]?.slug
      : profiles[profiles.length - 1]?.slug
  }`;

  return (
    <Layout location={location}>
      {profile && (
        <div className="Profile">
          <div className="banner" />
          <section className="bgSky nameSection">
            <div className="border" />
          </section>
          <section className="infoSection">
            <Link to={prevProfile}>
              <div className="arrow arrow__prev" />
            </Link>
            <Link to={nextProfile}>
              <div className="arrow arrow__next" />
            </Link>
            <Container justifySpaceBetween className="modefiedContainer">
              <div className="content content__description">
                {!isMobile && (
                  <div className="box__title">
                    <h2 className="title">
                      <b>{content?.name}</b>
                    </h2>
                    <h2 className="title">{content?.workTitle}</h2>
                  </div>
                )}
                {markdownParser(content?.description)}
                {isMobile && <ContactDetails content={content} />}
              </div>
              <div className="content content__details">
                <img
                  className="profilePicture"
                  src={content?.image}
                  alt="employee"
                />
                {isMobile && (
                  <div className="box__title">
                    <h2 className="title">
                      <b>{content?.name}</b>
                    </h2>
                    <h2 className="title">{content?.workTitle}</h2>
                  </div>
                )}

                {!isMobile && <ContactDetails content={content} />}
              </div>
            </Container>
          </section>
          {skills.length > 0 && (
            <section
              className="bgAccentLighter skillSection"
              id="profileSkills"
            >
              <Container flexDirection="flexColumn">
                <h3 className="title">
                  <b>skill - o - meter</b>
                </h3>
                <ul className="skill_list">
                  {skills.map(({ title, level, color }, index) => (
                    <li key={`${index + 1}`}>
                      <p className="title__skills">{title}</p>
                      <StyledProgressBar
                        color={color}
                        level={level}
                        fill={fill}
                      />
                    </li>
                  ))}
                </ul>
              </Container>
            </section>
          )}
          <link
            rel="stylesheet"
            href="//use.fontawesome.com/releases/v5.0.7/css/all.css"
          />
        </div>
      )}
    </Layout>
  );
};

Profile.defaultProps = {
  id: null,
};

Profile.propTypes = {
  id: PropTypes.string,
  location: PropTypes.shape().isRequired,
};

export default withStyles(s)(Profile);
