import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Link } from 'gatsby';
import { markdownParser } from '../../../../utils';
import timeline from '../../../../../content/the_band/timeline.json';
import 'react-vertical-timeline-component/style.min.css';
import Container from '../../../Container';
import Layout from '../../../layout';
import s from './Timeline.scss';

const dateObj = new Date();
const weekday = dateObj.toLocaleString('en-US', { weekday: 'long' });

const Timeline = ({ location }) => (
  <Layout location={location}>
    <div className="Timeline ">
      <section
        className="banner top bgMidnight"
      >
        <Container>
          <div className="content">
            <h1 className="title lead">
              <b>{timeline?.title}</b>
              {weekday}
            </h1>
            <p className="lead">
              {markdownParser(timeline?.body)}
            </p>
          </div>
          <img src="/decorations/tv.svg" alt="tv illustration" />
        </Container>
        <div className="bubblesBorder" />
      </section>
      <section className="timelineSection">
        <Container>
          <Link to="/the-band" className="returnButton bgSky">return</Link>
          <VerticalTimeline className="vertical-timeline-custom-line">
            {timeline.content.map(({
              display = false, date = null, title = null, body = null, icon = null,
            }) => (
              display && (
              <VerticalTimelineElement
                contentStyle={{ background: '#E5DBD2', color: '#000000', borderRadius: '15px' }}
                contentArrowStyle={{ borderRight: '0px solid  #fff' }}
                date={<b>{date}</b>}
                iconClassName="timeline__icon"
                icon={<i className={`fas fa-${icon}`} />}
                key={`${date}_${Math.random()}`}
              >
                <h5 className="vertical-timeline-element-title">{title}</h5>
                <p>
                  {body}
                </p>
              </VerticalTimelineElement>
              )
            ))}
          </VerticalTimeline>
        </Container>
      </section>
    </div>
  </Layout>
);

Timeline.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default withStyles(s)(Timeline);
