import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from '@reach/router';
import s from './Gallery.scss';

const Gallery = ({ images = [] }) => (
  <div className="Gallery">
    {images.map((image) => (
      <div key={image?.image || image.name} className="content__gallery">
        <Link className="learnMore" to={`${image.slug}`}>
          <div className="frame">
            <div
              className="image"
              style={{
                backgroundImage: `url(${image?.image})`,
              }}
            />
            <p className="learnMore">
              learn
              <br />
              more
            </p>
          </div>
        </Link>
        <span className="image__text">{image?.name}</span>
        <span className="workTitle">{image?.workTitle}</span>
      </div>
    ))}
  </div>
);

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
export default withStyles(s)(Gallery);
